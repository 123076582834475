import React from "react"
import "./Footer.css"

const date = new Date().getFullYear()

function Footer() {
  return <footer className='footer'>
    <div>© {date}</div>
  </footer>
}

export default Footer
