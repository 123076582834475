// ScrollIndicator.tsx

import React, { useEffect, useRef } from "react"
import "./ScrollDownIndicator.css"
import type { ScrollIndicatorProps } from "../@types/scroll"

function ScrollIndicator({ theme }: ScrollIndicatorProps) {
  const scrollIndicatorRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const maxScroll =
        document.documentElement.scrollHeight - window.innerHeight
      const scrollPercentage = scrollPosition / maxScroll
      const opacity = 1 - Math.min(1, scrollPercentage * 18)

      if (scrollIndicatorRef.current) {
        scrollIndicatorRef.current.style.opacity =
          opacity < 0 ? "0" : opacity.toString()
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div ref={scrollIndicatorRef} className={`scroll-indicator ${theme}`}>
      &#8964;
    </div>
  )
}

export default ScrollIndicator
