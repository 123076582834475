import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { ThemeContext } from "./contexts/theme"
import Container from "./container"
import Mode from "./components/Mode/Mode"

import "./App.css"

function App() {
  const { themeName } = useContext(ThemeContext) || {}

  return (
    <div id='top' className={`${themeName} app`}>
      <Routes>
        <Route path='/' element={<Container />} />
        <Route path='mode' element={<Mode />} />
      </Routes>
    </div>
  )
}

export default App
