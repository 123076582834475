import React from "react"
import Navbar from "../Navbar/Navbar"
import "./Header.css"

function Header() {
  return <header className='header center'>
    <h3>
      <a href='https://evanking.dev' className='link'>
        EK
      </a>
    </h3>
    <Navbar />
  </header>
}

export default Header
