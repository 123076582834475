import { Project } from "./@types/projects"

const projects: Project[] = [
  // TODO: Add projects
  // {
  //   name: "Project 1",
  //   description:
  //     "description here",
  //   stack: ["Example", "TypeScript", "React"],
  //   sourceCode: "https://github.com",
  //   livePreview: "https://github.com",
  // },
  // {
  //   name: "Project 2",
  //   description:
  //     "description here",
  //   stack: ["Example", "TypeScript", "React"],
  //   sourceCode: "https://github.com",
  //   livePreview: "https://github.com",
  // },
  // {
  //   name: "Project 3",
  //   description:
  //     "description here",
  //   stack: ["Example", "TypeScript", "React"],
  //   sourceCode: "https://github.com",
  //   livePreview: "https://github.com",
  // },
]

const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "GraphQL",
  "Git",
  "Netlify",
  "Jest",
  "Apollo",
]

export { projects, skills }
