import React, { useContext } from "react"
import Header from "./components/Header/Header"
import About from "./components/About/About"
import Projects from "./components/Projects/Projects"
import Skills from "./components/Skills/Skills"
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"
import Contact from "./components/Contact/Contact"
import Footer from "./components/Footer/Footer"
import ScrollIndicator from "./ScrollDownIndicator/ScrollDownIndicator"
import { ThemeContext } from "./contexts/theme" // Import your ThemeContext

function Container() {
  const { themeName } = useContext(ThemeContext)

  return (
    <div>
      <Header />

      <main>
        <About />
        <ScrollIndicator theme={themeName} />
        <Projects />
        <Skills />
        <Contact />
      </main>

      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default Container
