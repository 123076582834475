import React from "react"
import "./Contact.css"

function Contact() {
  return <section className='section contact center' id='contact'>
    <h2 className='section__title'>Contact</h2>
    <div className='contact__list'>
      <a href='mailto:evanking112@gmail.com'>
        <button type='button' className='btn btn--outline'>
          Email me
        </button>
      </a>
      <a href='https://linkedin.com/in/evanking11'>
        <button type='button' className='btn btn--outline'>
          LinkedIn
        </button>
      </a>
    </div>
  </section>
}

export default Contact
