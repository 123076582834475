import React from "react"
import ReactPlayer from "react-player"

function Mode() {
  return <div className='player about center'>
    <ReactPlayer
      url='https://d3b76d6aq0r0aw.cloudfront.net/matt-warzone-122922.MP4'
      controls
    />
  </div>
}

export default Mode
