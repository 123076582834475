import React from "react"
import { isMobile } from "react-device-detect"
import GitHubIcon from "@material-ui/icons/GitHub"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import profilepic from "../../images/pfp.jpg"
import resume from "../../documents/EvanKingResume2024.pdf"
import "./About.css"

function About() {
  return <div className='about center'>
    <div className='about__intro'>
      <img src={profilepic} id='profilePic' alt='profile pic' />
      <h1>
        Hey, I&#39;m {isMobile && <br />}
        <span className='about__name'>Evan King</span>
      </h1>
    </div>

    <h2 className='about__role'>Software Engineer.</h2>
    <p className='about__desc'>
      I&#39;m from Los Angeles, CA and I&#39;ve been building things on the
      internet since the Myspace days. I like food, games, puzzles, sports, and
      history. I&#39;ll be adding more here soon, but in the meantime, let&#39;s
      connect on <a href='http://linkedin.com/in/evanking11'>LinkedIn</a>!
    </p>

    <div className='about__contact center'>
      {resume && (
        <a href={resume} target='_blank' rel='noreferrer'>
          <button type='button' className='btn btn--outline'>
            Resume
          </button>
        </a>
      )}

      <a
          href='https://github.com/evank21'
          aria-label='github'
          className='link link--icon'
        >
          <GitHubIcon fontSize='large' />
        </a>

        <a
          href='https://linkedin.com/in/evanking11'
          aria-label='linkedin'
          className='link link--icon'
        >
          <LinkedInIcon fontSize='large' />
        </a>
    </div>
  </div>
}

export default About
