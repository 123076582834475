import React, { createContext, useEffect, useMemo, useState } from "react"

interface ThemeContextValue {
  themeName: string
  toggleTheme: () => void
}

export const ThemeContext = createContext<ThemeContextValue>({
  themeName: "dark",
  toggleTheme: () => {},
})

function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [themeName, setThemeName] = useState<string>(() => {
    const storedTheme = localStorage.getItem("themeName")
    return storedTheme || "light"
  })

  useEffect(() => {
    const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)")

    const handleChange = (e: MediaQueryListEvent) => {
      setThemeName(e.matches ? "dark" : "light")
    }

    setThemeName(darkMediaQuery.matches ? "dark" : "light")

    darkMediaQuery.addEventListener("change", handleChange)

    return () => {
      darkMediaQuery.removeEventListener("change", handleChange)
    }
  }, [])

  const toggleTheme = () => {
    const name = themeName === "dark" ? "light" : "dark"
    localStorage.setItem("themeName", name)
    setThemeName(name)
  }

  const contextValue = useMemo(
    () => ({ themeName, toggleTheme }),
    [themeName, toggleTheme]
  )

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider }
